.App {
  text-align: center;
  background-color: #769FCD;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.encounterForm{
  width: 100%;
  min-width: 500px;
  background-color: #D6E6F2;
  font-family:Arial, Helvetica, sans-serif
}

.referralForm{
  width: 100%;
  min-width: 500px;
  background-color: #D6E6F2;
  font-family: Arial, Helvetica, sans-serif
}

.prescriptionCard{
  text-align: left;
  background-color:#F7FBFC ;
  padding: 2;
}

.complianceCard{
  text-align: left;
  background: color #e7ffce; ;
  padding: 2;
}

.prescriptionCardSuperscript {
  font-family: sans-serif;
  font-size: large;
  color: #282c34;

}
.prescriptionCardSubscript {
  font-family: sans-serif;
  font-size: medium;
  color: #58a5d3;

}
.filterBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #B9D7EA;
}
/*palette https://colorhunt.co/palette/f7fbfcd6e6f2b9d7ea769fcd */

.EvenRow {
  background-color: whiteSmoke;
}
.sort{
  cursor: pointer
}